import './App.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Main from '../Main/Main';

function App() {

    return (
        <div className='app'>
            <Header/>
            <Main/>
            <Footer/>
        </div>
    );
}

export default App;
