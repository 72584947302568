export const FaqData = [
    {
        id: 1,
        title: 'Нужна ли мне консультация психолога?',
        content1: `Решать только вам!
      «Если мне нужен психолог, значит я -псих» - очень распространенное заблуждение.
      Психолог работает с психически здоровыми людьми, имеющими психологические проблемы.
      Вам нужен психолог, если вы умеете расчитывать свои силы и возможности и чувствуете, что сейчас вам их не хватает и вы нуждаетесь в помощи для решения сложившейся ситуации.
      Психолог - это не тот, кто будет решать за вас, это специалист, который вместе с вами готов рассмотреть ваш вопрос и найти ответ на него.`,
    },
    {
        id: 2,
        title: 'Как мне решиться на консультацию с психологом?',
        content1: `Решиться на консультацию с психологом не просто и это нормально. Это выбор взрослого, осознанного человека, которому необходимо разобраться в сложившейся ситуации. Вы можете взять несколько консультаций и попробовать. В любом случае у вас всегда есть право отказаться.`
    },
    {
        id: 3,
        title: 'Как проходит консультация?',
        content1: `Консультация проходит в дружественной, поддерживающей обстановке.
      В случае применения в ходе консультации терапевтических техник и методик психолог обязательно заранее информирует об этом клиента.
      Информация, озвучиваемая в ходе консультации клиентом и психологом строго конфиденциальна (за исключением сведений, носящих противоправный уголовный характер).`,
    }
    ,
    {
        id: 4,
        title: 'Сколько консультаций мне необходимо и с какой периодичностью?',
        content1: `Количество консультации оговаривается при первой встрече и носит рекомендательный характер. Если клиент готов на интенсивную терапию, то рекомендована периодичность встреч 1 раз в неделю и за клиентом закрепляется день и время встречи. Если терапия носит поддерживающий характер, то периодичность встреч может быть реже, день и время встречи оговариваются каждый раз заранее.`,
    }
];